
const designation_list = [
    {
        "designation_id": 1,
        "designation_name": "Founder & CEO"
    },
    {
        "designation_id": 2,
        "designation_name": "Chief Operating Officer (COO)"
    },
    {
        "designation_id": 3,
        "designation_name": "Director"
    },
    {
        "designation_id": 4,
        "designation_name": "Managing Director"
    },
    {
        "designation_id": 5,
        "designation_name": "Manager"
    },
    {
        "designation_id": 6,
        "designation_name": "Sr. Manager"
    },
    {
        "designation_id": 7,
        "designation_name": "Chairman"
    },
    {
        "designation_id": 8,
        "designation_name": "Co-founder & COO"
    },
    {
        "designation_id": 9,
        "designation_name": "Chief Executive Officer (CEO)"
    },
    {
        "designation_id": 10,
        "designation_name": "Chief Technology Officer (CTO)"
    },
    {
        "designation_id": 11,
        "designation_name": "Chief Marketing Officer (CMO)"
    },
    {
        "designation_id": 12,
        "designation_name": "Chief Engineer"
    },
    {
        "designation_id": 13,
        "designation_name": "Chief Financial Officer (CFO)"
    },
    {
        "designation_id": 14,
        "designation_name": "Founder"
    },
    {
        "designation_id": 15,
        "designation_name": "Designated Partner"
    },
    {
        "designation_id": 16,
        "designation_name": "Partner"
    },
    {
        "designation_id": 17,
        "designation_name": "Proprietor"
    },
    {
        "designation_id": 20,
        "designation_name": "Chief Medical Officer (CMO)"
    }
];

export { designation_list };

const business_natures = [
  {
      company_nob_id: 1,
      company_nob_name: "Manufacturer"
  },
  {
      company_nob_id: 2,
      company_nob_name: "Stockist"
  },
  {
      company_nob_id: 3,
      company_nob_name: "Dealer"
  },
  {
      company_nob_id: 4,
      company_nob_name: "Contractor"
  },
  {
      company_nob_id: 5,
      company_nob_name: "Consultant"
  },
  {
      company_nob_id: 6,
      company_nob_name: "Service Provider"
  }
];

export { business_natures };

const group_types = [

  
  {
      company_group_type_id: 1,
      company_group_type_name: "Metal"
  },
  {
      company_group_type_id: 2,
      company_group_type_name: "Steel"
  },
  {
      company_group_type_id: 3,
      company_group_type_name: "Pre-Insurance"
  },
  {
      company_group_type_id: 4,
      company_group_type_name: "Post Insurance"
  },
  {
      company_group_type_id: 5,
      company_group_type_name: "Hospitals"
  },
  {
      company_group_type_id: 6,
      company_group_type_name: "Service Provider"
  },
  {
      company_group_type_id: 7,
      company_group_type_name: "Electrical/HVAC/CIVIL"
  },
  {
      company_group_type_id: 8,
      company_group_type_name: "Hardware/IT"
  },
  {
      company_group_type_id: 9,
      company_group_type_name: "Manpower Supplier"
  },
  {
      company_group_type_id: 10,
      company_group_type_name: "House Keeping / Security"
  },
  {
      company_group_type_id: 11,
      company_group_type_name: "Logistics"
  },
  {
      company_group_type_id: 12,
      company_group_type_name: "Distributor"
  },
  {
      company_group_type_id: 13,
      company_group_type_name: "Retailer"
  }
];

export { group_types} ;


const industry_types = [
  {
    company_industry_type_id: 1,
      company_industry_type_name   : "Agriculture and Forestry"
  },
  {
      company_industry_type_id: 2,
      company_industry_type_name   : "Auto Components"
  },
  {
      company_industry_type_id: 3,
      company_industry_type_name   : "Automobile"
  },
  {
      company_industry_type_id: 4,
      company_industry_type_name   : "Aviation"
  },
  {
      company_industry_type_id: 5,
      company_industry_type_name   : "BFSI – Fintech & Financial Services"
  },
  {
      company_industry_type_id: 6,
      company_industry_type_name   : "Biotechnology"
  },
  {
      company_industry_type_id: 7,
      company_industry_type_name   : "Capital Goods"
  },
  {
      company_industry_type_id: 8,
      company_industry_type_name   : "Chemicals"
  },
  {
      company_industry_type_id: 9,
      company_industry_type_name   : "Construction"
  },
  {
      company_industry_type_id: 10,
      company_industry_type_name   : "Consumer Goods"
  },
  {
      company_industry_type_id: 11,
      company_industry_type_name   : "Defence Manufacturing"
  },
  {
      company_industry_type_id: 12,
      company_industry_type_name   : "E-Commerce"
  },
  {
      company_industry_type_id: 13,
      company_industry_type_name   : "Electric Mobility"
  },
  {
      company_industry_type_id: 14,
      company_industry_type_name   : "Electronic Components Manufacturing"
  },
  {
      company_industry_type_id: 15,
      company_industry_type_name   : "Electronic Systems"
  },
  {
      company_industry_type_id: 16,
      company_industry_type_name   : "Food Processing"
  },
  {
      company_industry_type_id: 17,
      company_industry_type_name   : "Fruits and Vegetables"
  },
  {
      company_industry_type_id: 18,
      company_industry_type_name   : "Furniture"
  },
  {
      company_industry_type_id: 19,
      company_industry_type_name   : "Gaming"
  },
  {
      company_industry_type_id: 20,
      company_industry_type_name   : "Handlooms and Handicrafts"
  },
  {
      company_industry_type_id: 21,
      company_industry_type_name   : "Healthcare"
  },
  {
      company_industry_type_id: 22,
      company_industry_type_name   : "IT and BPM"
  },
  {
      company_industry_type_id: 23,
      company_industry_type_name   : "Leather"
  },
  {
      company_industry_type_id: 24,
      company_industry_type_name   : "Man-made Fibres"
  },
  {
      company_industry_type_id: 25,
      company_industry_type_name   : "Media"
  },
  {
      company_industry_type_id: 26,
      company_industry_type_name   : "Metals and Mining"
  },
  {
      company_industry_type_id: 27,
      company_industry_type_name   : "Natural Gas"
  },
  {
      company_industry_type_id: 28,
      company_industry_type_name   : "Oil and Gas"
  },
  {
      company_industry_type_id: 29,
      company_industry_type_name   : "Pharmaceuticals"
  },
  {
      company_industry_type_id: 30,
      company_industry_type_name   : "Ports and Shipping"
  },
  {
      company_industry_type_id: 31,
      company_industry_type_name   : "Railways"
  },
  {
      company_industry_type_id: 32,
      company_industry_type_name   : "Renewable Energy"
  },
  {
      company_industry_type_id: 33,
      company_industry_type_name   : "Retail and E-commerce"
  },
  {
      company_industry_type_id: 34,
      company_industry_type_name   : "Roads and Highways"
  },
  {
      company_industry_type_id: 35,
      company_industry_type_name   : "Telecom"
  },
  {
      company_industry_type_id: 36,
      company_industry_type_name   : "Textiles and Apparel"
  },
  {
      company_industry_type_id: 37,
      company_industry_type_name   : "Thermal Power"
  },
  {
      company_industry_type_id: 38,
      company_industry_type_name   : "Tourism and Hospitality"
  },
  {
      company_industry_type_id : 39,
      company_industry_type_name   : "Toys Manufacturing"
  },
  {
    company_industry_type_id: 40,
      company_industry_type_name   : "White Goods"
  }
];

export {industry_types};

const business_types = [
  {
      company_business_type_id: 1,
      company_business_type_name: "Public Limited Company"
  },
  {
      company_business_type_id: 2,
      company_business_type_name: "Private Limited Company"
  },
  {
      company_business_type_id: 3,
      company_business_type_name: "Partnership"
  },
  {
      company_business_type_id: 4,
      company_business_type_name: "One Person Company (OPC)"
  },
  {
      company_business_type_id: 5,
      company_business_type_name: "Sole Proprietorship"
  },
  {
      company_business_type_id: 6,
      company_business_type_name: "Limited Liability Partnership (LLP)"
  }
];

export {business_types};

const industry_types_pc = [
    {
        company_industry_type_id: 1,
        company_industry_type_name: "Agriculture and Forestry",
        subtype: 0
    },
    {
        company_industry_type_id: 2,
        company_industry_type_name: "Auto Components",
        subtype: 0
    },
    {
        company_industry_type_id: 3,
        company_industry_type_name: "Automobile",
        subtype: 0
    },
    {
        company_industry_type_id: 4,
        company_industry_type_name: "Aviation",
        subtype: 0
    },
    {
        company_industry_type_id: 5,
        company_industry_type_name: "BFSI – Fintech & Financial Services",
        subtype: 0
    },
    {
        company_industry_type_id: 6,
        company_industry_type_name: "Biotechnology",
        subtype: 0
    },
    {
        company_industry_type_id: 7,
        company_industry_type_name: "Capital Goods",
        subtype: 0
    },
    {
        company_industry_type_id: 8,
        company_industry_type_name: "Chemicals",
        subtype: 0
    },
    {
        company_industry_type_id: 9,
        company_industry_type_name: "Construction",
        subtype: 0
    },
    {
        company_industry_type_id: 10,
        company_industry_type_name: "Consumer Goods",
        subtype: 0
    },
    {
        company_industry_type_id: 11,
        company_industry_type_name: "Defence Manufacturing",
        subtype: 0
    },
    {
        company_industry_type_id: 12,
        company_industry_type_name: "E-Commerce",
        subtype: 0
    },
    {
        company_industry_type_id: 13,
        company_industry_type_name: "Electric Mobility",
        subtype: 0
    },
    {
        company_industry_type_id: 14,
        company_industry_type_name: "Electronic Components Manufacturing",
        subtype: 0
    },
    {
        company_industry_type_id: 15,
        company_industry_type_name: "Electronic Systems",
        subtype: 0
    },
    {
        company_industry_type_id: 16,
        company_industry_type_name: "Food Processing",
        subtype: 0
    },
    {
        company_industry_type_id: 17,
        company_industry_type_name: "Fruits and Vegetables",
        subtype: 0
    },
    {
        company_industry_type_id: 18,
        company_industry_type_name: "Furniture",
        subtype: 0
    },
    {
        company_industry_type_id: 19,
        company_industry_type_name: "Gaming",
        subtype: 0
    },
    {
        company_industry_type_id: 20,
        company_industry_type_name: "Handlooms and Handicrafts",
        subtype: 0
    },
    {
        company_industry_type_id: 21,
        company_industry_type_name: "Healthcare",
        subtype: 0
    },
    {
        company_industry_type_id: 22,
        company_industry_type_name: "IT and BPM",
        subtype: 0
    },
    {
        company_industry_type_id: 23,
        company_industry_type_name: "Leather",
        subtype: 0
    },
    {
        company_industry_type_id: 24,
        company_industry_type_name: "Man-made Fibres",
        subtype: 0
    },
    {
        company_industry_type_id: 25,
        company_industry_type_name: "Media",
        subtype: 0
    },
    {
        company_industry_type_id: 26,
        company_industry_type_name: "Metals and Mining",
        subtype: 0
    },
    {
        company_industry_type_id: 41,
        company_industry_type_name: "Precious Metals",
        subtype: 26
    },
    {
        company_industry_type_id: 42,
        company_industry_type_name: "Industrial Metals",
        subtype: 26
    },
    {
        company_industry_type_id: 43,
        company_industry_type_name: "Ferrous Metals",
        subtype: 42,
        subsubtype: 42
    },
    {
        company_industry_type_id: 44,
        company_industry_type_name: "Non-ferrous Metals",
        subtype: 42,
        subsubtype: 42
    },
    {
        company_industry_type_id: 27,
        company_industry_type_name: "Natural Gas",
        subtype: 0
    },
    {
        company_industry_type_id: 28,
        company_industry_type_name: "Oil and Gas",
        subtype: 0
    },
    {
        company_industry_type_id: 29,
        company_industry_type_name: "Pharmaceuticals",
        subtype: 0
    },
    {
        company_industry_type_id: 30,
        company_industry_type_name: "Ports and Shipping",
        subtype: 0
    },
    {
        company_industry_type_id: 31,
        company_industry_type_name: "Railways",
        subtype: 0
    },
    {
        company_industry_type_id: 32,
        company_industry_type_name: "Renewable Energy",
        subtype: 0
    },
    {
        company_industry_type_id: 33,
        company_industry_type_name: "Retail and E-commerce",
        subtype: 0
    },
    {
        company_industry_type_id: 34,
        company_industry_type_name: "Roads and Highways",
        subtype: 0
    },
    {
        company_industry_type_id: 35,
        company_industry_type_name: "Telecom",
        subtype: 0
    },
    {
        company_industry_type_id: 36,
        company_industry_type_name: "Textiles and Apparel",
        subtype: 0
    },
    {
        company_industry_type_id: 37,
        company_industry_type_name: "Thermal Power",
        subtype: 0
    },
    {
        company_industry_type_id: 38,
        company_industry_type_name: "Tourism and Hospitality",
        subtype: 0
    },
    {
        company_industry_type_id: 39,
        company_industry_type_name: "Toys Manufacturing",
        subtype: 0
    },
    {
        company_industry_type_id: 40,
        company_industry_type_name: "White Goods",
        subtype: 0
    }

];
export {industry_types_pc};

const  roc_list = [
    
    {
        company_roc_id: 1,
        company_roc_name: "Hyderabad"
    },
    {
        company_roc_id: 2,
        company_roc_name: "Vijaywada"
    },
    {
        company_roc_id: 3,
        company_roc_name: "Guwahati"
    },
    {
        company_roc_id: 4,
        company_roc_name: "Patna"
    },
    {
        company_roc_id: 5,
        company_roc_name: "Bilaspur"
    },
    {
        company_roc_id: 6,
        company_roc_name: "Ranchi"
    },
    {
        company_roc_id: 7,
        company_roc_name: "Delhi"
    },
    {
        company_roc_id: 8,
        company_roc_name: "Goa"
    },
    {
        company_roc_id: 9,
        company_roc_name: "Ahmedabad"
    },
    {
        company_roc_id: 10,
        company_roc_name: "Himachal Pradesh"
    },
    {
        company_roc_id: 11,
        company_roc_name: "Jammu & Kashmir and UT of Ladakh"
    },
    {
        company_roc_id: 12,
        company_roc_name: "Bangalore"
    },
    {
        company_roc_id: 13,
        company_roc_name: "Ernakulam"
    },
    {
        company_roc_id: 14,
        company_roc_name: "Gwalior"
    },
    {
        company_roc_id: 15,
        company_roc_name: "Mumbai"
    },
    {
        company_roc_id: 16,
        company_roc_name: "Cuttack"
    },
    {
        company_roc_id: 17,
        company_roc_name: "Puducherry"
    },
    {
        company_roc_id: 18,
        company_roc_name: "Chandigarh"
    },
    {
        company_roc_id: 19,
        company_roc_name: "Jaipur"
    },
    {
        company_roc_id: 20,
        company_roc_name: "Chennai"
    },
    {
        company_roc_id: 21,
        company_roc_name: "Coimbatore"
    },
    {
        company_roc_id: 22,
        company_roc_name: "Kanpur"
    },
    {
        company_roc_id: 23,
        company_roc_name: "Dehradun"
    },
    {
        company_roc_id: 24,
        company_roc_name: "Uttarakhand"
    },
    {
        company_roc_id: 25,
        company_roc_name: "Kolkata"
    }
];

export  default roc_list;

const length = [
    {
        category_length_type_id: 0,
        category_length_type: "NA"
    },
    {
        category_length_type_id: 1,
        category_length_type: "Straight/Cut Length"
    },
    {
        category_length_type_id: 2,
        category_length_type: "Irregularly Wound Coils"
    },
];
export {length};

const form = [
    {
        category_form_id: 0,
        category_form: "NA"
    },
    {
        category_form_id: 1,
        category_form: "Long"
    },
    {
        category_form_id: 2,
        category_form: "Flat"
    },
];
export {form};

const stage = [
    {
        category_stage_id: 0,
        category_stage: "NA"
    },
    {
        category_stage_id: 1,
        category_stage: "Semi-finished"
    },
    {
        category_stage_id: 2,
        category_stage: "Finished"
    },
];
export {stage};

const slab = [
    {
        slab_id: 1,
        slab_value: "Yes"
    },
    { 
        slab_id: 0,
        slab_value: "No"
    },
];
export {slab};

const active = [
    {
        active: 1,
        active_value: "Yes"
    },
    { 
        active: 0,
        active_value: "No"
    },
];
export {active};
