
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import { useStore } from "vuex";
import {mask} from 'vue-the-mask'
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
import {active} from "@/core/data/genericData";

export default defineComponent({
  name: "edit-standard-modal", 
  directives: {mask}, 
  props: {
    data: {
        type: Object
    }
  },
  components: {    
    },    
  setup(props) {    
    const active_list_data = active;
    // this.$forceUpdate();
    const router = useRouter();    
    var formData = ref({})
    const store = useStore();
    const setFormData = async (data) => {    
        console.log(data.ids);
        var j = 0;

        try {

        var values = { "standard_id": data.ids, "page": 1, "records_per_page": 1 }
         await store.dispatch(Actions.CUST_GET_PRODUCT_STANDARD_LIST, values).then(({ data }) => {       
          formData.value = {
            id : data.standard_id,
            num: data.standard_no,
            standard_select: data.standard_master_id,
            comm: data.comm_no,
            title: data.standard_title,
            lic: data.no_of_lic,
            active : data.active,
            };
          console.log(formData.value)
          })
          .catch(({ response }) => {

            console.log(response);

          });
          
        } catch (e) {
          console.log(e);
        } 
        
    }


    onMounted( async () => {      
      await setFormData(props.data);
      await setStandardata(standard.value)
      });  

    
    const formRef = ref<null | HTMLFormElement>(null);
    const editProductStandardModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);    

    const rules = ref({
      num: [
        {
          required: true,
          message: "Standard Number is required",
          trigger: "change",  
             
        },  
      ],   
        standard_select: [
        {
          required: true,
          message: "Standard Type is required",
          trigger: "change",
              
        }, 
       ],
    });
    const standard = ref([]);
    const setStandardata = async (data) => {
      const db_data = {}
        //loading.value = true;
        try {
          await store.dispatch(Actions.CUST_GET_PRODUCT_STANDARD_MASTER_LIST, db_data).then(({ data }) => {
          standard.value = data.standard_list;
          //loading.value = false;
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const setProductStandardData = async (data) => {   
      //var user = JSON.parse(JwtService.getToken());       
      const db_data = {
            "standard_id" : data.id,
            "standard_no": data.num,
            "standard_master_id": data.standard_select,
            "comm_no": data.comm,
            "standard_title": data.title,
            "no_of_lic": data.lic,
            "active": data.active,
        }

        await store.dispatch(Actions.CUST_UPDATE_PRODUCT_STANDARD, db_data).then(({ data }) => {
        console.log(data);
        
        if (data)
        {
            setTimeout(() => {
              loading.value = false;
//
              Swal.fire({
                text: "Product Standard has been successfully Updated.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(editProductStandardModalRef.value);  
                router.go(0)           
              });
            }, 2000)          

          } else {

            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            })  
            return false;
          }
        })
      .catch(({ response }) => {
        console.log(response);        
      });
    };

   const submit = async () => {

      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate (async (valid) => {

        if (valid) {

          await setProductStandardData(formData.value);
        
        } else {

          loading.value = false;
          return false;
        }

      });  
    }
    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      setStandardata,
      standard,
      editProductStandardModalRef,
      active_list_data,
    };
  },
});
